<template>
  <div class="page">
    <el-container>
      <el-header>
        <Head :slide="slide" @logout="logout" :is_pelvic="1"></Head>
      </el-header>
      <el-main>
        <div class="">
          <ul class="bread-crumb">
            <li class="item item-3 fill">
              {{ $t("report.post") + $t("report.measure") }}
            </li>
            <li class="item item-3">
              {{ $t("report.post") + $t("report.install") }}
            </li>
            <li class="item item-4">
              {{ $t("report.post") + $t("report.stand") }}
            </li>
            <li class="item item-5">
              {{ $t("report.post") + $t("report.create") }}
            </li>
          </ul>

          <div class="main">
            <div class="right-side">
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="form"
                label-position="left"
              >
                <div class="reference">
                  <h2 class="content-title">
                    <div class="title">
                      <span>{{ $t("report.reference") }}:</span>
                    </div>
                  </h2>
                  <el-form-item label-width="auto">
                    <el-select
                      v-model="reference"
                      :placeholder="$t('report.placeholder')"
                    >
                      <el-option
                        v-for="item in refOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>

                <div class="reference">
                  <h2 class="content-title">
                    <div class="title">
                      <span>{{ $t("pelvic.parameters") }}</span>
                    </div>
                  </h2>

                  <div class="parameters-table">
                    <div class="parameters-item">
                      <div class="parameters-title">
                        <span>b</span>
                      </div>
                      <div class="parameters-input">
                        <el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.parameters.b"
                        ></el-input>
                      </div>
                    </div>
                    <div class="parameters-item">
                      <div class="parameters-title">
                        <span>c</span>
                      </div>
                      <div class="parameters-input">
                        <el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.parameters.c"
                        ></el-input>
                      </div>
                    </div>
                    <div class="parameters-item">
                      <div class="parameters-title">
                        <span>d</span>
                      </div>
                      <div class="parameters-input">
                        <el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.parameters.d"
                        ></el-input>
                      </div>
                    </div>
                    <div class="parameters-item">
                      <div class="parameters-title">
                        <span>f</span>
                      </div>
                      <div class="parameters-input">
                        <el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.parameters.f"
                        ></el-input>
                      </div>
                    </div>
                    <div class="parameters-item">
                      <div class="parameters-title">
                        <span>{{ $t("pelvic.ftbdistance") }}</span>
                      </div>
                      <div class="parameters-input">
                        <el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.parameters.ftbdistance"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="green">
                  <h2 class="content-title">
                    <div class="title green">
                      <span>{{ $t("report.theFront") }}</span>
                    </div>
                  </h2>
                  <div class="form-side-item">
                    <div class="form-side-item-child">
                      <el-form-item
                        label-width="150px"
                        class="green"
                        :label="$t('report.angle') + '（°）:'"
                        ><el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.front.angle"
                        ></el-input
                      ></el-form-item>
                      <el-form-item label-width="150px" label="" class="green">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.front.angleDir"
                        >
                          <el-radio class="radio" label="1">{{
                            $t("report.param1")
                          }}</el-radio>
                          <el-radio class="radio" label="0">{{
                            $t("report.param2")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <div class="form-side-item-child">
                      <el-form-item
                        class="green"
                        label-width="150px"
                        :label="$t('report.frontDistance') + '（mm）:'"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.front.distance"
                        ></el-input
                      ></el-form-item>
                      <el-form-item class="green" label-width="150px" label="">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.front.distanceDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param3")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param4")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="yellow">
                  <h2 class="content-title">
                    <div class="title yellow">
                      <span>{{ $t("report.theSide") }}</span>
                    </div>
                  </h2>
                  <div class="form-side-item">
                    <div class="form-side-item-child">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        :label="$t('report.angle') + '（°）:'"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.side.angle"
                        ></el-input
                      ></el-form-item>
                      <el-form-item class="yellow" label-width="150px" label="">
                        <el-radio-group :disabled="lock" v-model="ruleForm.side.angleDir">
                          <el-radio class="radio" label="0">{{
                            $t("report.param5")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param6")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="form-side-item-child">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        :label="$t('report.sideDistance') + '（mm）:'"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.side.distance"
                        ></el-input
                      ></el-form-item>
                      <el-form-item class="yellow" label-width="150px" label="">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.side.distanceDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param7")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param8")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="reference">
                  <h2 class="content-title">
                    <div class="title yellow">
                      <span>{{ $t("pelvic.parameters") }}</span>
                    </div>
                  </h2>
                  <div class="form-side-item">
                    <div class="form-side-item-child">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        label="d"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.reference.d"
                        ></el-input
                      ></el-form-item>
                      </el-form-item>
                    </div>
                    <div class="form-side-item-child">
                      <el-form-item
                        class="yellow"
                        label-width="150px"
                        :label="$t('pelvic.height_difference')"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.reference.height_difference"
                        ></el-input
                      ></el-form-item>
               
                    </div>
                  </div>
                </div>

                <div class="purple">
                  <h2 class="content-title">
                    <div class="title purple">
                      <span>{{ $t("report.theAxis") }}</span>
                    </div>
                  </h2>
                  <div class="form-side-item">
                    <div class="form-side-item-child">
                      <el-form-item
                        class="purple"
                        label-width="150px"
                        :label="$t('report.angle') + '（°）:'"
                        ><el-input
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.axis.angle"
                        ></el-input
                      ></el-form-item>
                      <el-form-item class="purple" label-width="150px" label="">
                        <el-radio-group :disabled="lock" v-model="ruleForm.axis.angleDir">
                          <el-radio class="radio" label="0">{{
                            $t("report.param9")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param10")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="form-side-item-child">
                      <el-form-item
                        class="purple"
                        label-width="150px"
                        :label="$t('report.axisDistance') + '（mm）:'"
                        ><el-input
                          :disabled="lock"
                          oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          v-model="ruleForm.axis.distance"
                        ></el-input
                      ></el-form-item>
                      <el-form-item class="purple" label-width="150px" label="">
                        <el-radio-group
                          :disabled="lock"
                          v-model="ruleForm.axis.distanceDir"
                        >
                          <el-radio class="radio" label="0">{{
                            $t("report.param11")
                          }}</el-radio>
                          <el-radio class="radio" label="1">{{
                            $t("report.param12")
                          }}</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                </div>

                <div class="btn-submit" @click="dialogVisible = true">
                  {{ $t("report.nextStep") }}
                </div>
                <div class="btn-submit" @click="complete">
                  {{ $t("report.complete") }}
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="$t('report.paramsConfirm')"
      :visible.sync="dialogVisible"
      width="30%"
      center
    >
      <div class="dialog">
        <ul>
          <li>{{ $t("report.theFront") }}</li>
          <li>
            {{
              ruleForm.front.angleDir == "0" ? $t("report.param2") : $t("report.param1")
            }}
            {{ ruleForm.front.angle + "°" }}
          </li>
          <li>
            {{
              ruleForm.front.distanceDir == "0"
                ? $t("report.param3")
                : $t("report.param4")
            }}
            {{ ruleForm.front.distance + "mm" }}
          </li>
        </ul>
        <ul>
          <li>{{ $t("report.theSide") }}</li>
          <li>
            {{
              ruleForm.side.angleDir == "0" ? $t("report.param5") : $t("report.param6")
            }}
            {{ ruleForm.side.angle + "°" }}
          </li>
          <li>
            {{
              ruleForm.side.distanceDir == "0" ? $t("report.param7") : $t("report.param8")
            }}
            {{ ruleForm.side.distance + "mm" }}
          </li>
        </ul>
        <ul>
          <li>{{ $t("report.theAxis") }}</li>
          <li>
            {{
              ruleForm.axis.angleDir == "0" ? $t("report.param9") : $t("report.param10")
            }}
            {{ ruleForm.axis.angle + "°" }}
          </li>
          <li>
            {{
              ruleForm.axis.distanceDir == "0"
                ? $t("report.param11")
                : $t("report.param12")
            }}
            {{ ruleForm.axis.distance + "mm" }}
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("report.BackToRevise")
        }}</el-button>
        <el-button type="primary" @click="submit">{{
          $t("report.ConfirmData")
        }}</el-button>
      </span>
    </el-dialog>
    <div class="popover" :style="popoverPosition" v-show="popoverShow">
      <div v-html="popoverContent"></div>
    </div>
  </div>
</template>
<script>
import Zoom from "../../components/Zoom";
export default {
  inject: ["reload"],
  components: {
    Zoom,
  },
  data() {
    return {
      isComplete: false,
      popoverShow: false,
      popoverPosition: { top: 0, left: 0 },
      popoverContent: "",
      dialogVisible: false, //确认弹出框
      id: "", //病例id
      ruleForm: {
        front: {
          angle: 0, //正位角度
          distance: 0, //正位位移
          angleDir: "0", //角度方向
          distanceDir: "0", //位移方向
        },
        side: {
          angle: 0,
          distance: 0,
          angleDir: "0",
          distanceDir: "0",
        },
        axis: {
          angle: 0,
          distance: 0,
          angleDir: "0",
          distanceDir: "0",
        },
        parameters: {
          b: 0,
          d: 0,
          c: 0,
          f: 0,
          ftbdistance: 0,
        },
        reference: {
          d: 0,
          height_difference: 0
        }
      },
      stage: null, //画布
      layer: null, //画笔
      stage2: null, //画布2
      layer2: null, //画笔2
      frontImageObj: new Image(), //正位初始图片对象
      sideImageObj: new Image(), //侧位初始图片对象
      oldWidth: document.documentElement.clientWidth * 0.32, // 画布初始宽度
      oldHeight: document.documentElement.clientHeight * 0.8, // 画布初始高度
      widthIn: document.documentElement.clientWidth * 0.32, // 当前画布宽度
      heightIn: document.documentElement.clientHeight * 0.8, // 当前画布高度
      widthIn2: document.documentElement.clientWidth * 0.32,
      heightIn2: document.documentElement.clientHeight * 0.8,
      xRay: null, //正位图
      xRayS: null, //侧位图
      multiple: 1,
      scale: 1,
      base: [],
      isConfirm: false,
      disabled: false,
      actual: 0, //参考线距离mm
      dist: 0, //参考线距离px
      frontTopTool: null, //正位上工具
      frontBottomTool: null, //正位下工具
      sideTopTool: null, //侧位上工具
      sideBottomTool: null, //侧位下工具
      slideText: "",
      slide: "",
      frontSlide: "0", // 正位患侧
      sideSlide: "0", // 侧位患侧
      reference: 0, //参考节段
      refOptions: [
        //参考节段选项
        {
          value: 0,
          label: this.$t("pelvic.ref1"),
        },
        {
          value: 1,
          label: this.$t("pelvic.ref2"),
        },
      ],
      lock: false, //是否锁定
      in_green: false,
      in_red: false,
    };
  },

  computed: {},

  watch: {
    "ruleForm.parameters": {
      handler(newVal, oldVal) {
        console.log(oldVal);
        if (newVal) {
          this.getAngle();
        }
      },
      deep: true, // 深度监听
    },
    "ruleForm.reference": {
      handler(newVal, oldVal) {
        console.log(oldVal);
        if (newVal) {
          this.getAxisAngle();
        }
      },
      deep: true, // 深度监听
    },
  },

  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.$api
        .getReport({
          before_after: 1,
          id: this.id,
        })
        .then((res) => {
          if (res.code) {
            const install = res.data.install;
            if (install) {
              this.reference = Number(install.far_near);
            }
            this.slideText = res.data.case.slide_text;
            this.slide = res.data.case.slide;
            this.base = res.data.base;

            this.ruleForm.front.angle = this.base[0].flip_angle ? this.base[0].flip_angle : 0;
            this.ruleForm.front.distance = this.base[0].slide_dis ? this.base[0].slide_dis : 0;
            this.ruleForm.front.angleDir = this.base[0].flip ? this.base[0].flip : 0;
            this.ruleForm.front.distanceDir = this.base[0].slide ? this.base[0].slide : 0;

            this.ruleForm.side.angle = this.base[1].flip_angle ? this.base[1].flip_angle : 0;
            this.ruleForm.side.distance = this.base[1].slide_dis ? this.base[1].slide_dis : 0;
            this.ruleForm.side.angleDir = this.base[1].flip ? this.base[1].flip : 0;
            this.ruleForm.side.distanceDir = this.base[1].slide ? this.base[1].slide : 0;

            this.ruleForm.axis.angle = this.base[2] && this.base[2].flip_angle ? this.base[2].flip_angle : 0;
            this.ruleForm.axis.distance = this.base[2] && this.base[2].slide_dis ? this.base[2].slide_dis : 0;
            this.ruleForm.axis.angleDir = this.base[2] && this.base[2].flip ? this.base[2].flip : "0";
            this.ruleForm.axis.distanceDir = this.base[2] && this.base[2].slide ? this.base[2].slide : "0";
          }
        });
    } else {
      this.$message.error(this.$t("common.errMsg2"));
      setTimeout((res) => {
        this.$router.go(-1);
      }, 500);
    }
  },
  mounted() {},
  methods: {
    getAngle() {
      const parameters = this.ruleForm.parameters;
      const _cos =
        (Math.pow(parameters.b, 2) +
          Math.pow(parameters.c, 2) -
          (Math.pow(parameters.ftbdistance, 2) +
            Math.pow(parameters.f, 2) -
            2 * parameters.ftbdistance * parameters.d)) /
        (2 * parameters.b * parameters.c);
      console.log("_cos", _cos);
      const angle = (180 / Math.PI) * Math.acos(_cos);
      console.log("angle", angle);
      if (isNaN(angle)) {
        this.ruleForm.front.angle = 0;
      } else {
        this.ruleForm.front.angle = angle.toFixed(2);
      }

      console.log("this.ruleForm.front.angle", this.ruleForm.front.angle);
    },
    getAxisAngle(){
      const reference = this.ruleForm.reference;
      const _sin = reference.height_difference / reference.d
      console.log("_sin", _sin);
      const angle = (180 / Math.PI) * Math.asin(_sin)

      console.log("angle", angle);
      if (isNaN(angle)) {
        this.ruleForm.axis.angle = 0;
      } else {
        this.ruleForm.axis.angle = angle.toFixed(2);
      }

      console.log("this.ruleForm.axis.angle", this.ruleForm.axis.angle);
    },
    //正位患侧信息修改
    frontSlideChange() {
      this.frontSlide = this.frontSlide == "0" ? "1" : "0";
      this.saveSlide();
    },
    //侧位患侧信息修改
    sideSlideChange() {
      this.sideSlide = this.sideSlide == "0" ? "1" : "0";
      this.saveSlide();
    },
    //保存患侧信息
    saveSlide() {
      this.$api.saveLeftRight({
        id: this.id,
        before_after: 1,
        left_right: JSON.stringify([
          { slide: this.frontSlide },
          { slide: this.sideSlide },
        ]),
      });
    },

    //提交，下一步
    async submit() {
      if (this.disabled) return false;
      this.dialogVisible = false;
      this.disabled = true;
      if (this.ruleForm.axis.angle === "") {
        this.disabled = false;
        return this.$message.error("请输入轴位角度");
      }
      if (this.ruleForm.axis.distance === "") {
        this.disabled = false;
        return this.$message.error("请输入轴位位移");
      }
      const dataFront = this.ruleForm.front;
      const dataSide = this.ruleForm.side;
      const dataAxis = this.ruleForm.axis;

      let obj = [
        {
          flip: dataFront.angleDir,
          flip_angle: dataFront.angle,
          slide: dataFront.distanceDir,
          slide_dis: dataFront.distance,
        },
        {
          flip: dataSide.angleDir,
          flip_angle: dataSide.angle,
          slide: dataSide.distanceDir,
          slide_dis: dataSide.distance,
        },
        {
          flip: dataAxis.angleDir,
          flip_angle: dataAxis.angle,
          slide: dataAxis.distanceDir,
          slide_dis: dataAxis.distance,
        },
      ];
      let resp = await this.$api.saveNear({
        id: this.id,
        near: this.reference,
      });
      if (resp.code) {
        return this.$api
          .savePelvicParams({
            id: this.id,
            line: this.actual,
            obj: JSON.stringify(obj),
            before_after: 1,
          })
          .then((res) => {
            //比例测量
            if (res.code) {
              if (this.isComplete) {
                return this.$router.push("/pelvic/stand?id=" + this.id);
              } else {
                return this.$router.push("/pelvic/installation?id=" + this.id);
              }
            }
            this.disabled = false;
            this.$message.error(res.msg);
          })
          .catch((err) => {
            console.log(err);
            this.disabled = false;
          });
      }
      this.$message.error(resp.msg);
    },

    reSection(type = "front") {
      let node =
        type == "front"
          ? this.$refs.frontBottomLineCenter.getNode()
          : this.$refs.sideBottomLineCenter.getNode();
      let points = node.points();
      let section =
        type == "front"
          ? this.$refs.frontSection.getNode()
          : this.$refs.sideSection.getNode();
      section.x(this.getPointX(points[2], points[3], points[4], points[5], section.y()));
    },

    //退出登录
    logout() {
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .logout()
            .then((res) => {
              if (res.code) {
                return this.$router.push("/login");
              }
              this.$message.error(res.msg);
            })
            .catch((err) => {
              // this.$message.error('系统繁忙');
            });
        })
        .catch(() => {});
    },

    //返回上一步
    lastStep() {
      this.$router.push("/post/level?id=" + this.id);
    },
    frontTopLine1MouseEnter(event) {
      console.log("frontTopLine1MouseEnter");
    },

    async complete() {
      this.isComplete = true;
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped>
.warp {
  width: 1440px;
}

.el-main {
  padding: 0 20px;
  background: #f1f5f7;
}

.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-header {
  background: #ffffff;
  text-align: right;
}

.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}

.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}

.btn-logout:hover {
  cursor: pointer;
}

.text-upload {
  margin: 17px 0;
  text-align: center;
  color: #7ecdf6;
  text-decoration: underline;
  cursor: pointer;
}

.main {
  /* height: ; */
  display: flex;
  flex-direction: column;
}

.main .canvas {
  width: 76.88%;
  /* height: 66.94%; */
  height: 540px;
  border-radius: 4px;
  background: #000000;
}

.main .right-side,
.main .left-side {
  background: #ffffff;
  border-radius: 4px;
  overflow-y: auto;
}

.right-side {
  padding: 0;
  background: #f1f5f7 !important;
}

.main .form {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.container {
  background: #000000;
  position: relative;
  margin: 0 5px;
}

.zoom {
  position: absolute;
  right: 30px;
  bottom: 26px;
  z-index: 99;
}

/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */
.step-list .item {
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 4px;
  color: #364284;
  margin-top: 14px;
  padding: 12px 22px;
}

.step-list .active {
  background: #364284;
  color: #ffffff;
}

.btn-submit,
.btn-comfirm,
.btn-revocation {
  width: 198px;
  height: 38px;
  line-height: 38px;
  border-radius: 4px;
  font-size: 14px;
  margin: 0 auto;
  color: #ffffff;
  background: #364284;
  border: 1px solid #364284;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.btn-revocation {
  background: #f1f5f7;
  border: 1px solid #364284;
  color: #364284;
  margin-top: 10px;
  margin-bottom: 50px;
}

.content-title {
  font-size: 14px;
  border-bottom: none;
}

.el-form-item {
  margin-bottom: 2px;
}

.konvajs-content {
  margin: auto !important;
}

.popover {
  padding: 10px;
  background-color: #fff;
  position: fixed;
  border-radius: 5px;
}

.popover::before {
  content: "";
  position: absolute;
  border: 8px solid;
  border-color: transparent transparent transparent #ffffff;
  right: -16px;
  top: 8px;
}

.form-side-item {
  display: flex;
  flex-direction: row;
}
.form-side-item-child {
  width: 50%;
}
.parameters-table {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.parameters-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
}
.parameters-title {
  font-size: 14px;
  padding: 10px 0;
}
</style>
